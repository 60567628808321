import React from 'react';
import {MoreDetail} from './component/button'

/* On the left */
const MyInfo = () =>{
    return(
        <div className="col-lg-3">
            <div className="row pt-2">
                <div className="col text-center  p-5">
                    <img className="rounded" id="contact-me-icon" src="img/home/icon.jpg" alt="How I look"></img>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row mt-3">
                    <div className="col">
                        <span className="h4">FAN Tsz Tsun</span>
                    </div>
                </div>
            
                {/* Phone */}
                <div className="row my-2">
                    <div className="col-12">
                        <button className='btn btn-outline-secondary' style={{display:"block", width:"100%"}}>
                            <span><a href="mailto:contact@f23an.com" style={{color:"inherit",verticalAlign:"middle"}}>contact@f23an.com</a></span>
                        </button>
                    </div>
                </div>
                
                {/* Email */}
                <div className="row my-2">
                    <div className="col-12">
                        <button className='btn btn-outline-secondary' style={{display:"block", width:"100%"}}>
                            <span><a href="tel:9417-7270" style={{color:"inherit"}}>(852) 9417 7270</a></span>
                        </button>
                    </div>
                </div>
                <hr className="my-2 my-lg-3"style={{width:"100%"}}></hr>
                <div className="row">
                    <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Remark: Email encryption</h5>
                            <span className="card-text">I am using Tutanota as my email service provider. To encrypt with PGP, please send it to </span>
                                <a href="mailto:knoll.repulsive317@anonaddy.me">knoll.repulsive317@anonaddy.me</a>
                            <button className='btn btn-outline-secondary mt-2' style={{display:"block", width:"100%"}}>
                                <a href='pgp key/0xBD070229-pub.asc' style={{color:"inherit"}}>Download PGP key</a>
                            </button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

/* One the right */
const MyProfile = () =>{
    return(
        <div>
            <div className="row">
                <div className="col">
                    <h3>Profile</h3>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <span className="lead">
                    Detail-oriented Creative Media graduate, with a passion for solving problems through creative technology solutions. Currently located in Vancouver, BC.
                    </span>
                </div>
            </div>
        </div>
    )
}

/* More detail button here */

const MyWorkExp = (props) =>{
    const workExperiences = [
        {   
            position: "Clerical Officer",
            company: "UOW College Hong Kong",
            period: "2021 August - 2022 February",
            duties: 
                [
                'Clerical support',
                'Problem solving with Python',
                'Promotion meterials design'
                ]
            
        },
        {   
            position: "UX/UI Developer Intern",
            company: "F-STEM Solution Ltd",
            period: "2020 June - August",
            duties: 
                [
                'Website design',
                'Website front-end building'
                ]
            
        },
        {
            position: "Intern",
            company: "Asia Insurance Co., Ltd.",
            period: "2019 June - July",
            duties: []
        },
        {
            position: "Part-time Sales Assistant",
            company: "Fotomax (F.E.) Ltd.",
            period: "2018 Sep - 2019 Mar",
            duties: 
                [
                'Providing services to customer',
                'Answering enquirers related to products and services'
                ]
            
        }
    ]

    return(
        <div className="row align-items-md-stretch py-2 py-md-0" id='workingExperience'>
                {workExperiences.map(workExperience =>(
                    <div className="col-xl-4 my-3 my-xs-0" style={{display: 'grid',}} key={workExperience.company}> {/* add display: 'grid' to solve align-items not working on safari 2022-03-26 */}
                            <div className="h-100 p-4  bg-light rounded">
                                <h5 className="display-5 fw-bold">{workExperience.position}</h5>
                                <p className="fs-4">{workExperience.company}</p>
                                <span>{workExperience.period}</span>
                                <div className="mt-2 collapse more-menu details" style={{opacity: "1"}}> 
                                    <ul className='pl-3 mb-0'>
                                        {workExperience.duties.map((duty,index) => (
                                            <li key={index}>{duty}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                    </div>
                ))}
            </div>
    )
}

const MyEducation = () =>{
    const educationHistory = [
        {
            programme:  "Bachelor of Science in Creative Media", 
            school:     "City University of Hong Kong",
            period:     "2019 - 2021",
            link:       "https://www.scm.cityu.edu.hk/programmes/undergraduate/BScCM"
        },
        {
            programme:  "Vancouver Summer Program - Earth, Ocean and Atmospheric Sciences", 
            school:     "University of British Columbia",
            period:     "2018 July - August",
            link:       "https://vancouversummerprogram.ubc.ca/"
        },
        {
            programme:  "Associate of Science in Creative and Interactive Media Production", 
            school:     "Community College of City University",
            period:     "2017 - 2019",
            link:       "https://www.uowchk.edu.hk/study-at-uowchk/find-programmes/programmes-by-study-area/creative-media-and-design/associate-of-science-in-creative-and-interactive-media-production/"
        }
    ];

    return(
        <div className="row align-items-md-stretch py-2 py-md-0" id='education'>
            {educationHistory.map((oneEducation,index) =>(
                <div className="col-xl-6 my-3 my-xs-0" style={{display: 'grid'}} key={index}> {/* add display: 'grid' to solve align-items not working on safari 2022-03-26 */}
                    <div className="h-100 p-4 p-md-5 bg-light rounded">
                    <h5 className="display-5 fw-bold">{oneEducation.programme}
                    <span>
                        {/* target="_blank" == open in new tab*/}
                        <a href={oneEducation.link} lang='en' target="_blank" rel="noreferrer"><i className="bi bi-link-45deg ml-1" style={{fontSize:"1em", verticalAlign:"middle", color:"gray"}} /></a>
                    </span>
                    </h5>
                    <p className="fs-4">{oneEducation.school} </p>
                    <span>{oneEducation.period}</span>
                    
                </div>
            </div>
            ))}
        </div>
    )
}

const MySkills = () =>{
    const skillsSet = [
        {
            category: "Office suite",
            level: "Proficient",
            skills: ['Microsoft Office', 'iWork']
        },
        {
            category: "Media production",
            level: "Fundamental knowledge",
            skills: ['Adobe Creative Cloud', 'Final Cut Pro', 'Sony Alpha']
        },
        {
            category: "Web development",
            level: "Fundamental knowledge",
            skills: ['HTML / CSS / JavaScript', 'ReactJS', 'Bootstrap','Node.js / Express', 'MySQL']
        },
        {
            category: "Programming",
            level: "Fundamental knowledge",
            skills: ['Git', 'Python', 'Arduino']
        }
    ];

    return (
        <div className="row align-items-md-stretch py-2 py-md-0" >
            {skillsSet.map((oneSet,index) =>(
                <div className="col-md-6 my-3 my-xs-0" style={{display: 'grid'}} key={index}> {/* add display: 'grid' to solve align-items not working on safari 2022-03-26 */}
                <div className="h-100 p-4 p-md-5 bg-light rounded">
                    <h4>{oneSet.category}</h4>
                <span className="badge badge-secondary mb-2">{oneSet.level}</span>
                    <ul className="skill-list pl-4 pl-md-1">
                        {oneSet.skills.map((skill,index) => (
                            <li key={index}><h6>{skill}</h6></li>
                        ))}
                    </ul>
                </div>
            </div>
            ))}
        </div>
      )


}

const MyInterest = () =>{
    const interestList = 
            [   "Photography", 
                "Videography",
                "Interactivity",
                "Read",
                "Inline skates",
                "Ping pong"
            ];

    return(
        <div className="row py-md-0">
            {interestList.map((interest, index) => (
                <div className="col-md-3 my-2 my-xs-0" key={index}>
                    <div className="p-2 pl-4 bg-light rounded">
                        <h5 className="m-0">{interest}</h5>
                    </div>
                </div>
            ))}
        </div>
    )
}

export const MyResume = () =>{
    return(
        <div className="container-fluid animate__animated animate__fadeIn px-4 px-md-5">
            <div className="row">
                <MyInfo />
                <div className="col-lg-9">
                    <div className="container-fluid py-4">
                        <MyProfile />
                        <div className="row mb-2">
                            <MoreDetail />
                        </div>
                        { /* Working exp */}
                        <div className="row mb-2">
                            <div className="col">
                                <h3>Working Experience</h3>
                            </div>
                        </div>
                        <MyWorkExp />
                        {/* ======================== */}
                        {/* Education */}
                        <div className="row mb-2">
                            <div className="col">
                                <h3>Education</h3>
                            </div>
                        </div>
                        <MyEducation />
                        {/* ======================== */}
                        {/* Skills */}
                        <div className="row my-2">
                            <div className="col">
                                <h3>Skills</h3>
                            </div>
                        </div>
                        <MySkills />
                        {/* ======================== */}
                        {/* Interest */}
                        <div className="row my-2">
                            <div className="col">
                                <h3>Interests</h3>
                            </div>
                        </div>
                        <MyInterest />
                    </div>
                </div>
            </div>
        </div>
    )
}