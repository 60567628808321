import React from 'react';

export const MyContact = () =>{
    return(
        <div>
            <div className="container d-flex p-3 mx-auto flex-column bg-light rounded animate__animated animate__fadeIn get-in-touch shadow-sm">
                <div className="row d-flex justify-content-center">
                    <div className="col-1"></div>
                    <div className="col text-center">
                        <h5 className='Raleway'>Get in touch</h5>
                    </div>
                    <div className="col-1"></div>
                </div>
                <div className="row">
                    <div className="col-3 text-left">
                        Phone:
                    </div>
                    <div className="col text-right">
                        <span><a href="tel:+85294177270">(852) 9417 7270</a></span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-1 text-left">
                        Email: 
                        </div>
                    <div className="col text-right">
                        <a href="mailto:contact@f23an.com">contactme@mail.f23an.com</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <small className="text-muted">Also available on <a href="sms://85294177270">iMessage</a>, <a href="http://t.me/f23an">Telegram</a> and Signal</small>
                    </div>    
                </div>
                {/* 
                <div className="row mt-2 pb-2 px-3">
                    <div className="card">
                        <div className="card-body">
                            <h6 className="card-title">Remark: Email encryption</h6>
                            <small className="card-text">I am using Tutanota as my email service provider. To encrypt with <a href="pgp key/0xBD070229-pub.asc">PGP</a>, please send it to </small>
                            <small><a href="mailto:knoll.repulsive317@anonaddy.me">contactwpgp@mail.f23an.com</a></small>
                        </div>
                    </div>
                </div>
                 */}
            </div>
            <div className="container d-flex p-3 mt-3 mx-auto flex-column bg-light rounded animate__animated animate__fadeIn get-in-touch shadow-sm">
                <div className="row">
                    <div className='col-6 my-auto'>
                        
                        <button className='btn btn-outline-secondary py-1' style={{display:"block", width:"100%"}}>
                        <i className="bi bi-instagram my-1" style={{fontSize:"24px", verticalAlign:"middle"}}/>                       
                            <a className="ml-2 my-1" style={{color:"inherit",verticalAlign:"middle" }} href="https://www.instagram.com/faninwild">faninwild</a>                     
                        </button>
                    </div>
                    <div className='col-6 my-auto'>

                        <button className='btn btn-outline-secondary py-1 fs-4' style={{display:"block", width:"100%"}}>
                            <i className="bi bi-instagram py-1" style={{fontSize:"24px",verticalAlign:"middle" }} />
                            <a className="ml-2 py-auto" style={{color:"inherit",verticalAlign:"middle" }} href="https://www.instagram.com/fanindisney">fanindisney</a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}