import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Waypoint } from 'react-waypoint';



export const PortfolioCell = () =>{
    
   /*  const testConsolelog = (currentCategory) =>{
        console.log(currentCategory);
    } */

    
    const removeSectionSelection = () =>{
        let allPortfoltioSection = ['programme', 'uxui', 'photography', 'video'];
        for (var i = 0; i<allPortfoltioSection.length;i++){
            var merge = 'portfolio-section-select-' + allPortfoltioSection[i];
            document.getElementById(merge).classList.remove('portfolio-section-select-active');
        }
    };
    
    /*
        const onEnterViewport = (valuepass) => {
        testConsolelog(valuepass);
    }
    
    const onExitViewport = (valuepas) => {

        testConsolelog(valuepass);
    }

    */
    
    const changeSectionSelection = (currentCategory) =>{
    
        removeSectionSelection();
    
        if (currentCategory === 'Programme' || currentCategory === 'Arduino'){
            let sectionSelected = document.getElementById('portfolio-section-select-programme');
            sectionSelected.classList.add('portfolio-section-select-active');
        }
        else if (currentCategory === 'UXUI'){
            let sectionSelected = document.getElementById('portfolio-section-select-uxui');
            /* sectionSelected.style.color = '#000000';
            sectionSelected.style.transition = 'color 2s'; */
            sectionSelected.classList.add('portfolio-section-select-active');
        }
        else if (currentCategory === 'Photography'){
            let sectionSelected = document.getElementById('portfolio-section-select-photography');
            /* sectionSelected.style.color = '#000000';
            sectionSelected.style.transition = 'color 2s'; */
            sectionSelected.classList.add('portfolio-section-select-active');
        }
        else if (currentCategory === 'Video'){
            let sectionSelected = document.getElementById('portfolio-section-select-video');
            /* sectionSelected.style.color = '#000000';
            sectionSelected.style.transition = 'color 2s'; */
            sectionSelected.classList.add('portfolio-section-select-active');
        }
    }
    
    const everyWork = [
        {
            key: 1,
            category: "Programme",
            title: "Ghost of You",
            oneLineDescription: "An interactive story",
            content: 
                <div>
                    <p>Ghost of You is created by HTML with JavaScript and CSS.</p>
                    <p>The message behind the story is to arise people awareness of privacy.</p>
                    <p>Interaction between the story and audiences is one of the greatest way to create an inspiring experience.</p>
                    <div style={{textAlign: 'center'}}> 
                        <a href="https://ghostofyou.f23an.com"><button className="btn btn-outline-secondary btn-getting-smaller"> Visit Ghost of You</button></a>
                    </div>
                    </div>,
            image: [
                {src: "img/portfolio/Programme_Ghostofyou/2.jpg"}, 
                {src: "img/portfolio/Programme_Ghostofyou/1.jpg"}
            ],
            isBackgroundLight: "bg-light",
            categoryFirstItem: "category-programme"
        },
        {
            key: 2,
            category: "Programme",
            title: "Tao",
            oneLineDescription: "Graduation Project",
            content: 
                <div>
                    <p>Tao is a multimedia project aims to express Tao using media. It includes different forms of media and technology finished by a group of 3.</p>
                    <p>I am responsible for the development of website, integration of 3D models for iOS AR and development of a simple program which uses Unity and Leap Motion.</p>
                </div>,
            image: [
                {src: "img/portfolio/FYP/web.png"}, 
                {src: "img/portfolio/FYP/ar.gif"},
                {src: "img/portfolio/FYP/unity.png"}
            ],
            video:"img/portfolio/FYP/ar.mp4",
            isBackgroundLight: ""
        },
        {
            key: 3,
            category: "Programme",
            title: "Java and Android application development",
            content: 
                <div>
                    <p>City Runner is a game created using Android Studio talking about Anthony’s decisions on his journey rushing to school for examination.</p>
                    <p>Recycle Kiosk is an imitation of a recycling kiosk system for users and administrators created using Java.</p>
                </div>,
            image: [
                {src: "img/portfolio/Programme_Java/android.jpg"}, 
                {src: "img/portfolio/Programme_Java/3.png"}
            ],
            isBackgroundLight: "bg-light"
        },
        {
            key: 4,
            category: "Arduino",
            title: "Anti anti anti smoke",
            content: 
            <div>
                <p>
                Anti anti anti smoke is a Arduino project aims to help smokers quit smoking.
                When a smoker want to smoke, the machine will determine whether a cigarette will be given or not. If not, a cindy will be popped out from the machine.
                </p>
                <p>
                When the button is pressed frequently, the probability of getting a cigarette will be decreased. 
                </p>
            </div>,
            image: [
                {src: "img/portfolio/Arduino_207/1.png"}, 
                {src: "img/portfolio/Arduino_207/2.png"},
                {src: "img/portfolio/Arduino_207/3.png"}, 
                {src: "img/portfolio/Arduino_207/4.png"} 
            ],
            isBackgroundLight: ""
        },
        {
            key: 5,
            category: "UXUI",
            title: "18 in one",
            oneLineDescription: "a UI/UX prototype focus on district communities",
            content: 
            <div>
                <p>18 in one is a concept of connecting district councillor with citizens.</p>
                <p>Group of five project. Images from the left are some pages that I contributed.</p>
            </div>,
            image: [
                {src: "img/portfolio/UIUX_18inone/merge.png"}
            ],
            isBackgroundLight: "bg-light",
            categoryFirstItem: "category-UIUX"
        },
        {
            key: 6,
            category: "Photography",
            title: "Sports Direct (Adidas) sponsored player",
            content: 
            <div >
                <h6>Adidas field hockey 2020</h6>
                <ul className="pl-4">
                <li>AX CARBON HOCKEY STICK</li>
                <li>LUX 2.0</li>
                <li>2020 OD GLOVE</li>
                <li>VS2 HOLDALL BAG</li>
                </ul>
            </div>,
            image: [
                {src: "img/portfolio/Photo_Adidas sponsored player/1.jpg"},
                {src: "img/portfolio/Photo_Adidas sponsored player/2.jpg"},
                {src: "img/portfolio/Photo_Adidas sponsored player/3.jpg"},
                {src: "img/portfolio/Photo_Adidas sponsored player/4.jpg"}
            ],
            isBackgroundLight: "",
            categoryFirstItem: "category-photography"
        },
        {
            key: 7,
            category: "Photography",
            title: <span><span className="walts">Disney </span>&#x26; Elegant</span>,
            content: 
            <div>
                <p>
                    The series Disney &#x26; Elegant show
                    different Disney elegancy.
                </p>
                <p>
                    Marry Poppins, a British nanny.
                    Princess Aurora and Belle, Beauty and confidence princess
                    Cast Members, performers bring happiness to people.
                </p>
            </div>,
            image: [
                {src: "img/portfolio/Photo_Disney/1.jpg"},
                {src: "img/portfolio/Photo_Disney/2.jpg"},
                {src: "img/portfolio/Photo_Disney/3.jpg"},
                {src: "img/portfolio/Photo_Disney/4.jpg"},
            ],
            isBackgroundLight: "bg-light"
        },
        {
            key: 8,
            category: "Photography",
            title: "UBC & Vancouver",
            content: 
            <div>
                <p>
                I joined Vancouver Summer Programme back to 2018 to experience Vancouver and learnt more about myself for a month.</p>
                <p>During this period, the gorgeous of Vancouver was captured through my eyes and camera.</p>
                <p>Four extraordinary sceneries presented by nature and human beings.</p>
            </div>,
            image: [
                {src: "img/portfolio/Photo_UBC/1.jpg"},
                {src: "img/portfolio/Photo_UBC/2.jpg"},
                {src: "img/portfolio/Photo_UBC/3.jpg"},
                {src: "img/portfolio/Photo_UBC/4.jpg"}
            ],
            isBackgroundLight: ""
        },
        {
            key: 9,
            category: "Video",
            title: "Kristina Conophy’s Live Performances",
            content: 
            <div>
                <p>This is a personal project started since 2016.</p>
                <p>
                It is a video production for Miss Conophy’s performance on stages.
                </p>
                <ul>
                <li><span className="walts" style={{fontSize: 'large'}}>Mickey and The Wondrous Book</span> (2017) -  Hong Kong </li>
                <li><span className="walts" style={{fontSize: 'large'}}>Voyage of The Little Mermaid</span> (2018) -  Orlando, Florida</li>
                <li><span className="walts" style={{fontSize: 'large'}}>House of De Vil-lains</span> (2023) -  Hong Kong</li>
                </ul>
            </div>,
            image: [
                {src: "img/portfolio/Video_k/1.png"},
                {src: "img/portfolio/Video_k/2.png"},
                {src: "img/portfolio/Video_k/3.png"},
                {src: "img/portfolio/Video_k/4.png"},
                {src: "img/portfolio/Video_k/5.png"}
            ],
            isBackgroundLight: "bg-light",
            categoryFirstItem: "category-video"
        },

        /*}
        {
            category: "test",
            title: "Video test props",
            content: 
            "Test only",
            image: [
                {src: "img/portfolio/Video_k/1.png"}
            ],
            video: {src: "img/portfolio/FYP/ar.mp4"},
            isBackgroundLight: "bg-light"
        },
        */
    ]

    const ongoingWork = [
        {
            key: 1,
            title: "Order Searcher And Record System",
            category:"On going",
            oneLineDescription: "a React Native project",
            content: <div>
                <p>Using React Native and Express, associate with a MySQL database.</p>
            </div>,
            image: [
                {src: "img/portfolio/Ongoing_programme_order/merge.png"}, 
                {src: "img/portfolio/Ongoing_programme_order/1.png"}, 
                {src: "img/portfolio/Ongoing_programme_order/2.png"},
                {src: "img/portfolio/Ongoing_programme_order/3.png"},
            ],
            isBackgroundLight: "",
        },
    ]
    return(
        <div className={"mx-1"}>
            {/* image need to recapture due to wrong spelling  */}
                {/* {ongoingWork.map(eachWork => (
                    <div className={"row mx-2 mx-md-1 my-4 py-3 shadow-sm rounded " + eachWork.isBackgroundLight} id={eachWork.categoryFirstItem} key={eachWork.key.toString()}>
                    <div className="col-lg-7 my-5">
                            
                        <Carousel showStatus={false} showIndicators={false} dynamicHeight={true} showThumbs={false} infiniteLoop={true}>
                           {eachWork.image.map(image => (
                               <div>
                                   <img src={image.src} alt={image.alt} />
                               </div>
                           ))}
                        </Carousel>
                       
                    </div>     
                         
                    <div class="col-lg-5">
                
                            <div className="py-1 py-lg-5">
                                <span style={{color:'gray', fontSize: '0.75rem', textTransform:'uppercase'}}>{eachWork.category}</span>
                                <h2 className='pt-1'>{eachWork.title}</h2>                
                                <h5 style={{color:'gray', marginBottom:'3.5vh'}}>{eachWork.oneLineDescription}</h5>
                                <hr></hr>
                                <p>{eachWork.content}</p>
                            </div>
                    </div>
                </div>
                ))} */}
                <div className='col-12' style={{padding:0}}>
                <hr style={{width:'100%', height:'0.25em', backgroundColor:'lightgray', borderRadius:'2.5px'}}/>
            </div>
                {everyWork.map(everyWork => (
                    <div className={"row mx-2 mx-md-1 my-4 py-3 shadow-sm rounded " + everyWork.isBackgroundLight} id={everyWork.categoryFirstItem} key={everyWork.key.toString()}>
                        {/* <div class="col-1"/> */}
                        <div className="col-lg-7 my-5">     
                            <Carousel showStatus={false} showIndicators={false} dynamicHeight={true} showThumbs={false} infiniteLoop={true}>
                               {everyWork.image.map(image => (
                                   <div>
                                       <img src={image.src} alt={image.alt}/>
                                   </div>
                               ))}
                            </Carousel>
                        </div>     
                             
                        <div class="col-lg-5">
                        <Waypoint topOffSet={'50%'} bottomOffset={'50%'} onEnter={function(props)  {
                          changeSectionSelection(everyWork.category)
                        }}
                        />
                                <div className="py-1 py-lg-5">
                                    <span style={{color:'gray', fontSize: '0.75rem', textTransform:'uppercase'}}>{everyWork.category}</span>
                                    <h2 className='pt-1'>{everyWork.title}</h2>                
                                    <h5 style={{color:'gray', marginBottom:'3.5vh'}}>{everyWork.oneLineDescription}</h5>
                                    <hr></hr>
                                    <p>{everyWork.content}</p>
                                </div>
                        </div>
                    </div>
                ))}
            </div>
    )
}


