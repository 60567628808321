import React from 'react';
import ReactDOM from 'react-dom';
import { MyResume } from "./resume";
import { MyPortfolio } from "./portfolio";

export const MyHome = () =>{
    const resumeRender = () =>{
        ReactDOM.render(<MyResume />,document.getElementById('root'));
    document.getElementById('nav-home').classList.remove('active');
    document.getElementById('nav-resume').classList.add('active');
    }

    const portfolioRender = () =>{
        ReactDOM.render(
            <MyPortfolio />,document.getElementById('root'));
        document.getElementById('nav-portfolio').classList.add('active');
        document.getElementById('nav-home').classList.remove('active');
    }

    return(
        <div className="d-flex flex-column">        
            <div className="background" id="background"></div>
                <div className="container p-5 animate__animated animate__fadeIn ">
                <div className="row">
                    <div className="col-lg-6">
                        <img id="myHomeImage" src="img/home/polaricon.jpg" alt=""></img>
                    </div>
                    <div className="col-lg-6 mt-lg-5 mt-2 pt-5 justify-content-center align-items-md-stretch text-center">
                        <div className="row mt-lg-5 mt-2 pt-lg-5 pt-3 mb-3">
                            <h1 className="col mt-xl-5 mt-lg-3 align-self-center mr-0 mr-lg-2">Hi, I am Fan.</h1>
                        </div>
                        <div className="row" style={{textAlign:'center'}}>
                            <div className='col-1 col-sm-2' />
                            {/* <div className="col-5 col-sm-4 align-self-center">
                                    <button type="button" className="btn btn-outline-secondary Raleway mr-2" style={{display:'block', width:'100%'}} onClick={resumeRender}>Resume</button>
                            </div> */}
                            {/* if resume is here  */}
                            {/* <div className="col-5 col-sm-4 align-self-center"> */}
                            {/* otherwise */}
                            <div className="col-10 col-sm-8 align-self-center">
                                <button type="button" className="btn btn-outline-secondary Raleway" style={{display:'block', width:'100%'}}  onClick={portfolioRender}>Portfolio</button>
                            </div>
                            <div className='col-1 col-sm-2' />
                        </div>                   
                    </div>
                    
                </div>
            </div>
        </div>
    )
}