import React from 'react';
import ReactDOM from 'react-dom';
// for react-responsive-carousel
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "bootstrap-icons/font/bootstrap-icons.css";

import {refreshStayInCurrentPageFor15Mins} from './currentPage.js';
import {cookieExists} from './currentPage.js';
// react component

import { HeaderHeader } from './component/header-header.js';
import { MyHome } from './Home.js';
import { MyFooter } from "./component/footer.js"
import { MyPortfolio } from './portfolio.js';
import { MyContact } from "./contactMe.js"
import { MyResume } from './resume.js';

ReactDOM.render(<HeaderHeader />, document.getElementById('header'));
ReactDOM.render(<MyFooter />,document.getElementById('footer'));

// Change the quote randomly 
Array.prototype.random = function () {
    return this[Math.floor((Math.random()*this.length))];
}
const phrase = [
    ["Those who do not move, do not notice their chains."],
    ["路上紛擾波折再一彎 一天想到歸去但已晚"],
    ["斜陽無限 無奈只一息間燦爛"]
];
document.getElementById("footer-quote").innerHTML = phrase.random();


var cookieCurrentPage;
if (cookieExists(cookieCurrentPage) === 0)
    document.cookie = 'curretPage=home;max-age=900;path=/';
/* 
cookieCurrentPage = document.cookie;
cookieCurrentPage = cookieCurrentPage.split('; ').find(row => row.startsWith('curretPage')).split('=')[1];  */

var name = "curretPage=";
var decodedCookie = decodeURIComponent(document.cookie);
var ca = decodedCookie.split(';');

for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
        cookieCurrentPage = c.substring(name.length, c.length);
    }
}
  
// Reader cookie to render
if (cookieCurrentPage === 'home'){
    ReactDOM.render(
        <MyHome />,
        document.getElementById('root')
    )
    document.getElementById('nav-home').classList.add('active');
    //document.getElementById('nav-resume').classList.remove('active');
    document.getElementById('nav-portfolio').classList.remove('active');
    document.getElementById('nav-contact').classList.remove('active');
    refreshStayInCurrentPageFor15Mins('home');
    
}
else if (cookieCurrentPage === 'resume'){

    async function backToTopFirst(){
        backToTop();
        ReactDOM.render(
            <MyResume />,
            document.getElementById('root'));
        document.getElementById('nav-home').classList.remove('active');
        //document.getElementById('nav-resume').classList.add('active');
        document.getElementById('nav-portfolio').classList.remove('active');
        document.getElementById('nav-contact').classList.remove('active');
        refreshStayInCurrentPageFor15Mins('resume');
    }
    remove_hash_from_url();
    backToTopFirst();
    
}
else if (cookieCurrentPage === "portfolio"){

    async function deleteHashAndRender(){
        backToTop();
        remove_hash_from_url();
        renderPortfolioWhenRefresh();
    }
    
    function renderPortfolioWhenRefresh(){
        ReactDOM.render(
            <MyPortfolio />,
            document.getElementById('root')
        )
        document.getElementById('nav-home').classList.remove('active');
        //document.getElementById('nav-resume').classList.remove('active');
        document.getElementById('nav-portfolio').classList.add('active');
        document.getElementById('nav-contact').classList.remove('active');
        refreshStayInCurrentPageFor15Mins('portfolio');
    }
    deleteHashAndRender();
}
else if (cookieCurrentPage === "contact"){
    backToTop();
    ReactDOM.render(
        <MyContact />,
        document.getElementById('root')
    );
    document.getElementById('nav-home').classList.remove('active');
    //document.getElementById('nav-resume').classList.remove('active');
    document.getElementById('nav-portfolio').classList.remove('active');
    document.getElementById('nav-contact').classList.add('active');
    refreshStayInCurrentPageFor15Mins('contact');
}
else {
    backToTop();
    ReactDOM.render(
        <MyHome />,
            document.getElementById('root'));
}


function remove_hash_from_url() {
    var uri = window.location.toString();

    if (uri.indexOf("#") > 0) {
        var clean_uri = uri.substring(0,
                        uri.indexOf("#"));

        window.history.replaceState({},
                document.title, clean_uri);
    }
};

function backToTop(){
    window.scrollTo(0,-5000);
}
