import React from 'react';

export const PortfolioSelection = () =>{

    const style ={
        fontSize: 'meduime', 
        textTransform:'uppercase'
    };
    return (
        <div className='sticky-top pt-5 mt-5 portfolio-section-select' id='portfolio-section-select' style={{textAlign: "right", top:"10vh"}}> 
            <ul className='ml-3 pl-0' style={{ listStyleType: 'none', color:'grey'}}>
                <li><a href='#category-programme' className='portfolio-section Raleway' id='portfolio-section-select-programme' style={style}>Programme</a></li>
                <li><a href='#category-UIUX' className='portfolio-section Raleway' id='portfolio-section-select-uxui' style={style}>UI/UX</a></li>
                <li><a href='#category-photography' className='portfolio-section Raleway' id='portfolio-section-select-photography' style={style}>Photography</a></li>
                <li><a href='#category-video' className='portfolio-section Raleway' id='portfolio-section-select-video' style={style}>Video</a></li>
                {/* <li><a href='#category-ongoing' className='portfolio-section Raleway' id='portfolio-section-select-ongoing' style={style}>On Going</a></li> */}
            </ul>
        </div>
    )
}