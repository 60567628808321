import React from 'react';

export const MyFooter = () =>{



    return(
        <footer className="footer animate__animated animate__fadeIn">
            <div className="container" id="footer-quote-container">
                <span className="text-muted pl-0" id="footer-quote"></span>
            </div>
        </footer>
    )
}