import React,{ useState } from 'react';
import ReactDOM from 'react-dom';
import {refreshStayInCurrentPageFor15Mins} from '../currentPage.js';
import {HomePageTitle} from './header-title';
import { MyContact } from '../contactMe.js';
import { MyPortfolio } from '../portfolio';
import { MyResume } from '../resume.js';
import { MyHome } from '../Home.js';

export const HeaderHeader = () => {

    const [isHome, setHomeActive] = useState('active');
    const [isResume, setResumeActive] = useState();
    const [isPortfolio, setPortfolioActive] = useState();
    const [isContact, setContactActive] = useState();

    const RemoveActive = () =>{
        setHomeActive('');
        setResumeActive('');
        setPortfolioActive('');
        setContactActive('');
    }

    const click = (page) => {
        const AllHeader = ['nav-home', 'nav-resume', 'nav-portfolio', 'nav-contact'];
        
        RemoveActive();
        if (page === AllHeader[0]){
            setHomeActive('active');
            ReactDOM.render(
                <MyHome />,
                document.getElementById('root')
            );
            refreshStayInCurrentPageFor15Mins('home');
        }
        else if (page === AllHeader[1]){
            setResumeActive('active');
            var position = document.getElementById('root').getBoundingClientRect();
            async function backToTopFirst(){
                backToTop();
                /* The following code temparory solve the problem that
                    when the portfolio page has scrolled a bit, clicking the header resume button
                    will not render the page back to top.
                */

                let timeout;
                if (position.top < 0){
                    timeout = setTimeout(renderElementsAfter_backToTop, 300);
                    // console.log('if'); 
                }
                else{
                    renderElementsAfter_backToTop();
                    // console.log('else')
                }
                function renderElementsAfter_backToTop(){
                    ReactDOM.render(
                        <MyResume />,
                        document.getElementById('root'));
                    
                    refreshStayInCurrentPageFor15Mins('resume');  
                    
                };
               
            }
            backToTopFirst();
            remove_hash_from_url();
        }
        else if (page === AllHeader[2]){
            setPortfolioActive('active');
            backToTop();
            ReactDOM.render(
                <MyPortfolio />,
                document.getElementById('root')
            );
            refreshStayInCurrentPageFor15Mins('portfolio');
        }
        else{
            setContactActive('active');
            ReactDOM.render(
                <MyContact />,
                document.getElementById('root')
            );
            refreshStayInCurrentPageFor15Mins('contact');
        }
        
    }

    function remove_hash_from_url() {
        var uri = window.location.toString();
    
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0,
                            uri.indexOf("#"));
    
            window.history.replaceState({},
                    document.title, clean_uri);
        }
    };
    
    function backToTop(){
        window.scrollTo(0,-5000);
    }
    

    return (
        <nav className="navbar navbar-expand-lg navbar-light ml-0 mx-lg-5">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="Raleway collapse navbar-collapse mt-2 mt-lg-2" id="navbarToggler">
                {/* <a className="navbar-brand" href="https://reactjs.org/">Made with ReactJS</a> */}
                <HomePageTitle />
                <ul className="navbar-nav ml-auto ">
                    {/* should change to button instead of <a></a> */}
                    <li className={isHome+" nav-item"} id="nav-home"><a className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" aria-current="page" onClick={() => click('nav-home')}>Home</a></li>
                    {/* <li className={isResume+" nav-item"} id="nav-resume" ><a className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" onClick={() => click('nav-resume')}>Resume</a></li> */}
                    <li className={isPortfolio+" nav-item"} id="nav-portfolio"><a className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" onClick={() => click('nav-portfolio')}>Portfolio</a></li>
                    <li className={isContact+" nav-item"} id="nav-contact"><a className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" onClick={() => click('nav-contact')}>Contact Me</a></li>
                </ul>
            </div>
        </nav>
        );
}