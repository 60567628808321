import React,{ useState } from 'react';

/* transform More Details button at resume to a useState button */
export const MoreDetail = () => {
  const [isToggle, setIsToggle] = useState(false);
  const [name, setName] = useState('Show details');

  const flip = () => {
    if (isToggle){
      setIsToggle(false);
      setName('Show details');

    } else{
      setIsToggle(true);
      setName('Hide details');
    }
  }

  return (
    <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3">
       <button type="button" 
          data-toggle="collapse" data-target=".more-menu" 
          className="btn btn-outline-secondary" id="moreDetailToggle" 
          style={{width: "100%"}} 
          onClick={() => flip()}
          >
          {name}
      </button>
    </div>
  )
}







