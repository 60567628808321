import React from 'react';

import {PortfolioCell} from './component/portfolio-cell';
import {PortfolioSelection} from './component/portfolio-selection';
/* https://www.npmjs.com/package/react-waypoint */

/* Good to know, not working btw */
/* https://github.com/terwanerik/ScrollTrigger */
/* https://stackoverflow.com/questions/33973648/react-this-is-undefined-inside-a-component-function */

/* How to use map */
/* https://stackoverflow.com/questions/50202069/how-to-map-a-2d-array-in-react-js */

/* TODO: To map video */
/* https://stackoverflow.com/questions/49471454/react-js-uncaught-typeerror-props-video-map-is-not-a-function */


export const MyPortfolio = () =>{

    return(
        <div className="container-fluid animate__animated animate__fadeIn" id="pagePortfolio" >
                <div className='container-fluid px-0 px-xs-1' id="portfolio">
                    
                    <div className='row px-1'>
                        {/* 
                        <div className='col-12 col-xs-10 col-sm-8 mx-auto'>
                            <div className="alert alert-info alert-dismissible fade show" role="alert" id='page-under-construction'>
                                <span>This page is still under construction.</span>
                            </div>
                        </div>
                       */}
                    </div>
                     
                    <div className='row'>
                        <div className='col-1 col-md-2 portfolio-section-select'>
                            <PortfolioSelection />
                        </div>
                        <div className='col-12 col-md-8 col-sm-12 px-0 px-xs-1 mx-md-auto'>
                            <PortfolioCell />
                        </div>
                        <div className='col-1 col-lg-2 hide' />
                    </div>
                </div>
                
            </div>
    )
}

