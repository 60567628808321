//https://stackoverflow.com/questions/48211891/import-functions-from-another-js-file-javascript#48212558

/* export function sayHi(){
    alert("hi");
} */

export function refreshStayInCurrentPageFor15Mins(currentPageIs){
    deleteAllCookies();
    document.cookie = 'curretPage='+currentPageIs+';max-age=900;path=/';
    // console.log(document.cookie);
}

export function cookieExists(name) {
    var cks = document.cookie.split(';');
    for(var i = 0; i < cks.length; i++)
      if (cks[i].split('=')[0].trim() === name) 
      return true;
  //https://stackoverflow.com/questions/5968196/check-cookie-if-cookie-exists
}

//delete all cookies
export function deleteAllCookies() {
    var cookie = document.cookie.split(';');
    for (var i = 0; i < cookie.length; i++) {
        var chip = cookie[i],
            entry = chip.split("="),
            name = entry[0];
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT;';
    }
}

