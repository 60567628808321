import React,{ useState, useEffect } from 'react';
import "./header-title.css";

/* a <a> element changes after a period of time*/

export const HomePageTitle = () =>{
    const [title, setTitle] = useState('ReactJS');
    const [link, setLink] = useState('https://reactjs.org/');
    const [binary, setBinary] = useState('false'); 
    
        //false == React JS
        //true == Boostrap

    useEffect(() => {
        setTimeout(() => {
           if (binary){ 
                setBinary(false);
                setTitle('ReactJS');
                setLink('https://reactjs.org/');
           }
           else{
                setBinary(true);
                setTitle('Boostrap');
                setLink('https://getbootstrap.com/');
           }
        }, 10000);
        }, [binary]);   // <==== When every time binary being changed by setTimeout(), will trigger useEffect() again
                        // run time will look like: 1. useEffect uns on the first render 2. if else will trigger setState 3. binay changed, so run useEffect again

        // setInterval may do similar stuff without adding title in useEffect()
        // It will run fine in first couple time but will become buggy
        
    
    return(
        <a className="navbar-brand" id="header-title" 
            href={link}>
            Make with {title}
        </a>
    )
}